import React, { useState, useEffect } from "react";
import axios from "axios";
import { VehicalList } from "./VehicalList";

export const Tours = () => {
  const [data, setData] = useState([]);

  const getData = async () => {
    const result = await axios.get("http://localhost:5050/packages");
    console.log(result.data);
    setData(result.data);
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <React.Fragment>
      {data.map((item) => (
        <h1>{item}</h1>
      ))}
    </React.Fragment>
  );
};
